import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import { Header, PostList, Orga, RequestChart } from 'components';
import { Layout, Container } from 'layouts';
import Img from 'gatsby-image';
import Typed from 'react-typed';
import dna from '../../static/dna.webp';
import theme from '../../config/theme';


const PostWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0rem 0rem 0rem 0rem;
  @media (max-width: 1000px) {
    margin: 0rem 2rem 1rem 2rem;
  }
  @media (max-width: 700px) {
    margin: 0rem 1rem 1rem 1rem;
  }
`
const EdgeMargin = styled.div`
@supports (-ms-ime-align:auto) {
  height: 100px;
}
`
const Button = styled.button`
  border: 2px solid ${theme.colors.primary.base};
  border-radius:5px;
  color: ${theme.colors.primary.base};
  background-color: rgba(255, 255, 128, 0.0);
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  transition: ${theme.transitions.default.transition};
  &:hover {
    box-shadow: ${theme.shadow.feature.small.hover};
    transform: scale(1.04);
  }
`

const UFCLogo = styled(Img)`
  width: 200px;
  margin-left: 2%;
  margin-top: -35px;
  margin-bottom: 0px;
  @media (max-width: 1300px) {
    display: none;
  }`
const ChronoLogo = styled(Img)`
  width: 200px;
  margin-top: 25px;
  margin-right: 5%;
  float: right;
  @media (max-width: 1300px) {
    display: none;
  }`


const Index = ({ data }) => {
  const { edges } = data.allMarkdownRemark;
  return (
    <Layout>
      <Helmet title={'2B2S'} />
      <Header title="2B2S">
        <span style={{ marginLeft: '60px' }}>Plateforme pour analyses <b>
          <span style={{ display: 'inline-block', width: '175px', textAlign: 'left' }}>
            <Typed
              strings={[
                'RNA-Seq',
                'métagénomiques',
                'phylogéniques'
              ]}
              startDelay={1000}
              backDelay={3000}
              typeSpeed={50}
              backSpeed={30}
              loop
            />
          </span>
        </b>
        </span>
      </Header>
      <EdgeMargin></EdgeMargin>
      <a href="http://univ-fcomte.fr"><UFCLogo fixed={data.univLogo.childImageSharp.fixed} style={{ display: 'inherit', position: "absolute"}}/></a>
      <a href="https://www.chu-besancon.fr/"><ChronoLogo fixed={data.chronoLogo.childImageSharp.fixed} style={{ display: 'inherit' }} /></a>
      <Container center={null}>
        <div style={{ "textAlign": "center", "display": "block" }}>
          <div style={{ 'textAlign': "left", 'display': "inline-block", 'marginTop': '-20px' }}>
            <h1>
              Bioinformatique et <br />
              Big data au <br />
              Service de la <br />
              Santé</h1>
            <br />
          </div>
        </div>
        <p align = "justify">
          <b>2B2S</b> est une plateforme hospitalo-universitaire dédiée à la formation et à la recherche. Elle est le produit du regroupement de la plateforme universitaire 2B2S de l’UFR Sciences de la Santé (créée en 2019), de l’Unité de Fonctionnement Bioinformatique (UF7790) du CHU de Besançon, et du pôle bioinformatique de la Team TIM-C de l’UMR INSERM 1098. Elle rassemble des savoir-faire relatifs au traitement informatique de données massives de biologie issues des différentes unités de recherche de l’UFR Sciences de la Santé, des services de biologie et de Centres Nationaux de Référence du CHU. Toutes les équipes de recherche du CHU, de l’Établissement Français du Sang de Besançon et de l'Université de Franche-Comté sont également concernées. Le renforcement des moyens dédiés et la mutualisation de l'expertise permettent de développer et de coordonner des projets de recherche innovants, notamment en oncologie et en microbiologie. 
        </p>
      </Container>
      <Container>
        <h1>Dernières publications</h1>
      </Container>
      <Container>
        <PostWrapper>
          {edges.map(({ node }) => {
            const { id, excerpt, frontmatter } = node;
            const { cover, path, title, date } = frontmatter;
            return (
              <PostList
                key={id}
                cover={cover.childImageSharp.fluid}
                path={path}
                title={title}
                date={date}
              />
            );
          })}
        </PostWrapper>
      </Container>
      <Container center={null}>
        <h1 id="ctx">
          Contexte
      </h1>
        <p align = "justify">
          Les avancées technologiques en médecine et en biologie ont permis ces dernières années l’accumulation de quantités colossales de données (big data). L’analyse de ces données grâce aux développements constants dans le domaine de l’informatique, sont à l’origine de véritables révolutions dans tous les secteurs des sciences de la vie. Depuis quelques années, l’élément limitant n’est plus la génération des big data mais leur exploitation correcte. La production de ces données (séquençage d’ADN, transcriptomique, protéomique, imagerie) peut être locale ou externalisée. A l'inverse, l'exploitation des big data à des fins de recherche requiert des ajustements fins des paramètres d’analyse, une optimisation des pipelines, la multiplication de contrôles ou la création de nouveaux outils. Ainsi, la plateforme 2B2S privilégie le maintien, le développement, et la mise en commun de compétences bioinformatiques locales pour l’analyse des données massives en biologie. La proximité et le dialogue entre les bioinformaticien(ne)s et les chercheurs en biologie et en médecine permet l’analyse approfondie de données de RNAseq, de métabolomique, de composition du microbiote digestif, de cancérologie. L’augmentation du niveau de connaissance bioinformatique permet aux chercheurs d’envisager des recherches plus ambitieuses, plus structurées et plus collaboratives. 
      </p>
      </Container>
      <Container center={null}>
        <h1>A qui s'adresse la plateforme</h1>
        <p align = "justify">
          La plateforme est accessible aux acteurs de la recherche comme au monde de l'entreprise. Ses
          ingénieurs peuvent accompagner les projets depuis la mise en place du protocole jusqu'à la
          production d'articles scientifiques. Elle sert donc de support à des projets de recherche
          fondamentale ou clinique et est ouverte aux coopérations régionales, nationales et internationales.
          Par ailleurs, la plateforme a une vocation de formation des étudiants (Santé, Master 1, Master 2,
          Doctorants) à l’utilisation des techniques de bio-informatique et d’analyse base de données de biologie.
        </p>
      </Container>
      <Orga />
      <Container>
        <h1>Étapes pour la <br />réalisation d'un <br />projet</h1>
        <RequestChart />
      </Container>
      <Container center={null}>
        <h1 id="ctct">Contacts</h1>
        <div style={{ "textAlign": "left", "display": "block" }}>
          Afin que nous puissions étudier votre demande, merci de compléter et de nous retourner le formulaire disponible en cliquant sur le bouton ci-dessous :
        </div>
        <br />
        <br />
        <br />
        <div style={{ "textAlign": "center", "display": "block" }}>
          <a href="/2B2Sform.pdf" target="_blank"><Button>Télécharger le formulaire de demande</Button></a>
        </div>
      </Container>
      <Container center={null}>
      <a href="http://univ-fcomte.fr"><UFCLogo fixed={data.univLogo.childImageSharp.fixed} style={{'marginTop': "50px", "marginBottom": "50px", "display":"inline-block"}}/></a>
      <a href="https://www.chu-besancon.fr/"><ChronoLogo fixed={data.chronoLogo.childImageSharp.fixed} style={{'marginTop': "110px", "display":"inline-block"}} /></a>
      </Container>
    </Layout>
  );
};

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            excerpt: PropTypes.string,
            frontmatter: PropTypes.shape({
              cover: PropTypes.object.isRequired,
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              date: PropTypes.string.isRequired,
              tags: PropTypes.array,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export const query = graphql`
  query {
    chronoLogo: file(relativePath: { eq: "logo/chrono.png" }) {
      childImageSharp {
        fixed(
          width: 200
          ) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        } 
      }
    }
    univLogo: file(relativePath: { eq: "logo/univ.png" }) {
      childImageSharp {
        fixed(
          width: 250
          ) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        } 
      }
    }
    allMarkdownRemark(
      limit: 6
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 75)
          frontmatter {
            title
            path
            tags
            date(formatString: "DD.MM.YYYY")
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 1000
                  quality: 90
                  traceSVG: { color: "#2B2B2F" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
